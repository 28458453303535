import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionService } from './modules/details/services/subscription/subscription.service';
import { Subscription } from 'rxjs';
import { LangService } from './modules/details/services/language/lang.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private langChangeSubscription: Subscription;
  public direction = '';
  constructor(private langSvc: LangService) {
    if (this.langSvc.selectedAppLang == '1') {
      this.direction = 'ltr';
    } else if (this.langSvc.selectedAppLang == '2') {
      this.direction = 'rtl';
    }
    this.langChangeSubscription = this.langSvc.langChangedEmitter.subscribe(
      (lang: any) => {
        this.direction = '';
        if (this.langSvc.selectedAppLang == '1') {
          this.direction = 'ltr';
        } else if (this.langSvc.selectedAppLang == '2') {
          this.direction = 'rtl';
        }
      }
    );
    }

  ngOnInit(): void {
    if (localStorage.getItem('language') == null) {
      this.direction = '';
      this.setCookie('googtrans', '/en/ar');
    } else if (localStorage.getItem('language') == '2') {
      this.direction = 'rtl';
    } else if (localStorage.getItem('language') == '1') {
      this.direction = 'ltr';
  }

  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
    }

  getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
  }


  setCookie(name: string, val: string) {

    const date = new Date();
    const value = val;

  //   // Set it expire in 7 days
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

  //   // Set it
    document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  }

  changeLanguage(e) {
    if (e.target.value === 'en') {
      this.langSvc.selectedAppLang = '1';
    } else if (e.target.value === 'ar') {
      this.langSvc.selectedAppLang = '2';
    }
  }

}

