import { EventEmitter, Injectable, Output } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LangService {

  @Output()
  public langChangedEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {

  }


  private _selectedAppLang =
    localStorage.getItem('language') == '2' ? '2' : '1';

  public get selectedAppLang() {
    return this._selectedAppLang;
    //  return AppLangEnum.English;
  }
  public set selectedAppLang(v) {
    this._selectedAppLang = v;
    localStorage.setItem('language', v);
    this.langChangedEmitter.emit(v);
  }

}
